import React, { useEffect, useState } from 'react';
import './App.css';
import { v4 as uuidv4 } from "uuid";
import copy from "copy-to-clipboard"

function App() {
  const [uuid, setUuid] = useState(uuidv4())
  const [copied, setCopied] = useState(false)

  const copyUuid = () => {
    copy(uuid)
    setCopied(true)
  }

  const refresh = () => {
    setUuid(uuidv4())
    setCopied(false)
  }

  useEffect(() => {
    if(copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  })

  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-center">
        <div className="text-3xl font-bold mb-8 mt-32">
          <h1>UUID Generator</h1>
        </div>
        <div className="mb-8">
          <pre>{uuid}</pre>
        </div>
        <div className="mb-8 flex flex-row font-bold">
          <div>
            <button onClick={refresh} className="refresh">
              Refresh
            </button>
          </div>
          <div>
            <button onClick={copyUuid} className="copy">
              {copied ? "Copied" : "Copy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
